<template>
  <div id="stats-year">
    <StatsAccountYear />
  </div>
</template>

<script>
import StatsAccountYear from '../../../components/stats/account/StatsAccountYear'

export default {
  components: {
    StatsAccountYear
  }
}
</script>
